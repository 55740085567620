<!--
   /**
      * footer.vue
      * @module {src/modules/public}
      * @desc 前端底部
      * @author shiqiu
      * @date 2021-01-20
      */
-->
<template>
  <div>
    <el-row type="flex" justify="center" class="footerNavMaxBg">
      <el-col :xs="24" :sm="24" :md="22" :lg="20" :xl="20" style="margin-top: 10px;">
        <el-row :gutter="10" style="border-bottom: 1px solid #999;padding-bottom: 10px">
<!--          <el-col :xs="8" :sm="8" :md="5" :lg="5" :xl="5">-->
<!--            <p class="footerNav" style="line-height: 30px">我们的产品</p>-->
<!--            <el-row :gutter="10">-->
<!--              <el-col :span="24">-->
<!--                <p class="footerNavMin" @click="toIotGateways">物联网网关</p>-->
<!--              </el-col>-->
<!--              <el-col :span="24">-->
<!--                <p class="footerNavMin" @click="toApi">智能API中心</p>-->
<!--              </el-col>-->
<!--              <el-col :span="24">-->
<!--                <p class="footerNavMin" @click="toPropertySystem">智能物业系统</p>-->
<!--              </el-col>-->
<!--              <el-col :span="24">-->
<!--                <p class="footerNavMin" @click="toDigitalCenter">数字统筹中心</p>-->
<!--              </el-col>-->
<!--            </el-row>-->
<!--          </el-col>-->
<!--          <el-col :xs="8" :sm="8" :md="5" :lg="5" :xl="5">-->
<!--            <p class="footerNav" style="line-height: 30px">我们的服务</p>-->
<!--            <el-row :gutter="10">-->
<!--              <el-col :span="24">-->
<!--                <p class="footerNavMin" @click="toCommunitySolution">智慧社区</p>-->
<!--              </el-col>-->
<!--              <el-col :span="24">-->
<!--                <p class="footerNavMin" @click="toParkSolution">智慧园区</p>-->
<!--              </el-col>-->
<!--              <el-col :span="24">-->
<!--                <p class="footerNavMin" @click="toOfficeBuilding">智慧写字楼</p>-->
<!--              </el-col>-->
<!--              <el-col :span="24">-->
<!--                <p class="footerNavMin" @click="toHotelSolution">智慧酒店</p>-->
<!--              </el-col>-->
<!--              <el-col :span="24">-->
<!--                <p class="footerNavMin" @click="toBarracksSolution">智慧军营</p>-->
<!--              </el-col>-->
<!--            </el-row>-->
<!--          </el-col>-->
<!--          <el-col :xs="8" :sm="8" :md="10" :lg="10" :xl="10">-->
<!--            <p class="footerNav" style="line-height: 30px">关于我们</p>-->
<!--            <el-row :gutter="10">-->
<!--              <el-col :span="24">-->
<!--                <p class="footerNavMin" @click="toAboutUs">公司简介</p>-->
<!--              </el-col>-->
<!--              <el-col :span="24">-->
<!--                <p class="footerNavMin" @click="toContact">联系我们</p>-->
<!--              </el-col>-->
<!--            </el-row>-->
<!--          </el-col>-->
<!--          <el-col :xs="8" :sm="8" :md="21" :lg="21" :xl="21">-->
<!--            <p class="footerNav" style="line-height: 30px">加入我们</p>-->
<!--            <el-row :gutter="10">-->
<!--              <el-col :span="24">-->
<!--                <p class="footerNavMin" @click="toJoinProperty">加入物业</p>-->
<!--              </el-col>-->
<!--              <el-col :span="24">-->
<!--                <p class="footerNavMin" @click="toCityPartner">成为城市合伙人</p>-->
<!--              </el-col>-->
<!--              <el-col :span="24">-->
<!--                <p class="footerNavMin" @click="toBecomeBusinessman">成为商家</p>-->
<!--              </el-col>-->
<!--            </el-row>-->
<!--          </el-col>-->
          <el-col :xs="8" :sm="8" :md="21" :lg="21" :xl="21" style="margin-top: 25px">
            <p style="line-height: 30px;color: #999;font-size: 14px">广州富融信息技术有限公司</p>
            <p style="line-height: 30px;color: #999;font-size: 14px">联系电话：13672460740</p>
            <p style="line-height: 30px;color: #999;font-size: 14px">联系邮箱：service@rich-f.com</p>
            <p style="line-height: 30px;color: #999;font-size: 14px">公司地址：广州市天河区天河北路663号广东省机械研究所8栋801</p>
          </el-col>
          <el-col :xs="8" :sm="8" :md="3" :lg="3" :xl="3">
            <el-row :gutter="10">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <p style="text-align: center"><img src="@/assets/images/qrcode.jpg" style="width: 100%" /></p>
                <p style="text-align: center;color: #999">官方微信</p>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
    <div class="indexFooter">
      <p>© 2019 广州富融信息技术有限公司 | All rights Reserved <el-link target="_blank" href="http://beian.miit.gov.cn/" style="color: #999">粤ICP备16067505号</el-link></p>
    </div>
      </el-col>
    </el-row>
  </div>


</template>

<script>

export default {
  name: "Login",
  data() {
    return {
    }
  },
  methods: {
    // 物联网网关
    toIotGateways(){
      this.$router.push({path:'/iot_gateways'})
    },
    // 智能API中心
    toApi(){
      this.$router.push({path:'/api'})
    },
    // 智能物业系统
    toPropertySystem(){
      this.$router.push({path:'/property_system'})
    },
    // 数字统筹中心
    toDigitalCenter(){
      this.$router.push({path:'/digital_center'})
    },
    // 智慧社区
    toCommunitySolution(){
      this.$router.push({path:'/community_solution'})
    },
    // 智慧园区
    toParkSolution(){
      this.$router.push({path:'/park_solution'})
    },
    // 智慧写字楼
    toOfficeBuilding(){
      this.$router.push({path:'/office_building'})
    },
    // 智慧酒店
    toHotelSolution(){
      this.$router.push({path:'/hotel_solution'})
    },
    // 智慧军营
    toBarracksSolution(){
      this.$router.push({path:'/barracks_solution'})
    },
    // 新闻资讯
    toNews(){
      this.$router.push({path:'/news'})
    },

// 公司简介
    toAboutUs(){
      this.$router.push({path:'/about'})
    },
// 联系我们
    toContact(){
      this.$router.push({path:'/contact'})
    },
    // 加入物业
    toJoinProperty(){
      this.$router.push({path:'/join_property'})
    },
    // 成为城市合伙人
    toCityPartner(){
      this.$router.push({path:'/city_partner'})
    },
    // 成为商家
    toBecomeBusinessman(){
      this.$router.push({path:'/become_businessman'})
    }

  },
  created() {

  }
}
</script>


<style lang="scss" scoped>
.indexFooter {
  float: left;
  width: 100%;
}

.indexFooter p {
  line-height: 40px;
  font-size: 12px;
  text-align: center;
  color: #999
}

.waveWindow {
  float: left;
  width: 50%;
  position: absolute;
  z-index: 9999999;
  top: 20vh;
  right: 0
}

.waveWindowBg {
  background: #3bc8e1;
  padding: 10px;
  margin: 10px;
}

.waveWindowBg p {
  text-align: center;
  color: #fff;
  font-size: 20px;
  line-height: 50px;
}
.footerNavBg {
  margin-top: 10px;
  float: left;
  width: 100%;
  background: #eee;
  border-top:1px solid #999;
  border-bottom: 1px solid #fff;
}
.footerNav{
  text-align: left;
  line-height: 50px;
  font-size: 16px;
  color: #999;
  cursor: pointer;
}
.footerNav:active {
  color: #f90;
  outline: 0;
}
.footerNav:focus, .footerNav:hover {
  color: #f90;
}
.footerNavMin{
  font-size: 12px;
  color: #999;
  line-height: 25px;
  cursor: pointer;
}
.footerNavMin:active {
  color: #f90;
  outline: 0;
}
.footerNavMin:focus, .footerNavMin:hover {
  color: #f90;
}
.footerNavMaxBg{
  float: left;
  width: 100%;
  background: #374250;
  margin-top: 40px;
}
</style>
