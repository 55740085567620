<!--
   /**
      * header.vue
      * @module {src/modules/public}
      * @desc 前端头部
      * @author shiqiu
      * @date 2021-01-20
      */
-->
<template>
  <div>
    <div class="topbg">
      <el-row type="flex" justify="center">
        <el-col :xs="24" :sm="24" :md="22" :lg="20" :xl="20">
          <div class="logo" @click="toHome">
            <img src="@/assets/logo.png" style="width: 12vw">
          </div>
          <div class="topRight">

            <el-button icon="el-icon-s-fold" v-show="!topNav" @click="drawer = true" class="unwindBtn" style="margin-right: 10px;margin-left: 10px;float: right">
            </el-button>
            <el-drawer
              title="我是标题"
              :visible.sync="drawer"
              :with-header="false">
              <el-menu
                default-active="2"
                class="el-menu-vertical-demo"

                style="border-right: none">
                <el-menu-item index="1" @click="toHome" style="margin:  0px 0px">
                  <span slot="title">首页</span>
                </el-menu-item>
<!--                <el-submenu index="2">-->
<!--                  <template slot="title">我们的产品</template>-->
<!--                  <el-menu-item index="2-1" @click="toIotGateways">物联网网关</el-menu-item>-->
<!--                  <el-menu-item index="2-2" @click="toApi">智能API中心</el-menu-item>-->
<!--                  <el-menu-item index="2-3" @click="toPropertySystem">智能物业系统</el-menu-item>-->
<!--                  <el-menu-item index="2-4" @click="toDigitalCenter">数字统筹中心</el-menu-item>-->
<!--                </el-submenu>-->
<!--                <el-submenu index="3">-->
<!--                  <template slot="title">我们的服务</template>-->
<!--                  <el-menu-item index="3-1" @click="toCommunitySolution">智慧社区</el-menu-item>-->
<!--                  <el-menu-item index="3-2" @click="toParkSolution">智慧园区</el-menu-item>-->
<!--                  <el-menu-item index="3-3" @click="toOfficeBuilding">智慧写字楼</el-menu-item>-->
<!--                  <el-menu-item index="3-4" @click="toHotelSolution">智慧酒店</el-menu-item>-->
<!--                  <el-menu-item index="3-5" @click="toBarracksSolution">智慧军营</el-menu-item>-->
<!--                </el-submenu>-->
<!--                <el-menu-item index="4" @click="toNews">新闻中心</el-menu-item>-->
<!--                <el-submenu index="5">-->
<!--                  <template slot="title">关于我们</template>-->
<!--                  <el-menu-item index="5-1" @click="toAboutUs">公司简介</el-menu-item>-->
<!--                  <el-menu-item index="5-2" @click="toContact">联系我们</el-menu-item>-->
<!--                </el-submenu>-->
                <el-menu-item index="2" @click="toNanny" style="margin:  0px 0px">
                  <span slot="title">保姆服务</span>
                </el-menu-item>
                <el-menu-item index="3" @click="toCleanKeeping" style="margin:  0px 0px">
                  <span slot="title">保洁服务</span>
                </el-menu-item>
                <el-menu-item index="4" @click="toHousing" style="margin:  0px 0px">
                  <span slot="title">房屋维护</span>
                </el-menu-item>
                <el-menu-item index="5" @click="toPlumbing" style="margin:  0px 0px">
                  <span slot="title">管道疏通</span>
                </el-menu-item>
                <el-submenu index="6">
                  <template slot="title">加入我们</template>
                  <el-menu-item index="6-1" @click="toJoinProperty">加入物业</el-menu-item>
                  <el-menu-item index="6-2" @click="toCityPartner">成为城市合伙人</el-menu-item>
                  <el-menu-item index="6-3" @click="toBecomeBusinessman">成为商家</el-menu-item>
                </el-submenu>
                
              </el-menu>
            </el-drawer>
<!--            <el-link style="float:right" target="_blank" href="https://system.rich-f.cn/#/login">-->
            <el-button icon="el-icon-mouse" class="newBtn" @click="toAgreement" style="float:right">登录</el-button>
<!--            </el-link>-->
            <el-link style="float:right" target="_blank" href="https://system.rich-f.cn/#/tryOut">
            <el-button class="newBtn" style="float:right;margin-right: 10px"><i class="iconfont icon-tianmaoshiyongfuwu" style="padding-right: 10px" />试用</el-button>
            </el-link>
            <el-link style="float:right" target="_blank" href="https://system.rich-f.cn/#/repIndex">
            <el-button class="newBtn" style="float:right;margin-right: 10px"><i class="iconfont icon-chenggongjiaru" style="padding-right: 10px" />进驻</el-button>
            </el-link>
            <el-input placeholder="请输入内容" v-model="search" class="input-with-select" style="float: right" v-show="topSearch">
              <el-button slot="append" icon="el-icon-search"></el-button>
            </el-input>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>


</template>

<script>

export default {
  name: "Login",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽度
      topNav: true,
      topSearch: true,
      drawer: false,
      search:''
    }
  },
  mounted() {
// 监听窗口大小
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth
      })()
    }
  },
  watch: {
    screenWidth : {
      handler:"windowWith",
      immediate: true
    }
  },
  methods: {
    toNanny(){
      this.$router.push({path:'/nannyList'})
    },
    toCleanKeeping(){
      this.$router.push({path:'/cleanKeepingList'})
    },
    toHousing(){
      this.$router.push({path:'/housingList'})
    },
    toPlumbing(){
      this.$router.push({path:'/plumbingList'})
    },
    toAgreement(){
      this.$router.push({path:'/agreement'})
    },
    windowWith(){
      if (this.screenWidth < 1100) {
        this.topNav = false
        this.topSearch = false
      } else {
        this.topNav = true
        this.topSearch = true
      }
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    //跳转登录
    toLogin(){
      this.$router.push({path:'/login'})
    },
    //跳转捐赠
    toDonate(){
      this.$router.push({path:'/donate'})
    },
    //跳转页面
    toHome(){
      this.$router.push({path:'/'})
    },
    // 物联网网关
    toIotGateways(){
      this.$router.push({path:'/iot_gateways'})
    },
    // 智能API中心
    toApi(){
      this.$router.push({path:'/api'})
    },
    // 智能物业系统
    toPropertySystem(){
      this.$router.push({path:'/property_system'})
    },
    // 数字统筹中心
    toDigitalCenter(){
      this.$router.push({path:'/digital_center'})
    },
    // 智慧社区
    toCommunitySolution(){
      this.$router.push({path:'/community_solution'})
    },
    // 智慧园区
    toParkSolution(){
      this.$router.push({path:'/park_solution'})
    },
    // 智慧写字楼
    toOfficeBuilding(){
      this.$router.push({path:'/office_building'})
    },
    // 智慧酒店
    toHotelSolution(){
      this.$router.push({path:'/hotel_solution'})
    },
    // 智慧军营
    toBarracksSolution(){
      this.$router.push({path:'/barracks_solution'})
    },
    // 新闻资讯
    toNews(){
      this.$router.push({path:'/news'})
    },

// 公司简介
    toAboutUs(){
      this.$router.push({path:'/about'})
    },
// 联系我们
    toContact(){
      this.$router.push({path:'/contact'})
    },
    // 加入物业
    toJoinProperty(){
      this.$router.push({path:'/join_property'})
    },
    // 成为城市合伙人
    toCityPartner(){
      this.$router.push({path:'/city_partner'})
    },
    // 成为商家
    toBecomeBusinessman(){
      this.$router.push({path:'/become_businessman'})
    }
  },
  created() {

  }
}
</script>


<style lang="scss" scoped>
/*头部*/
.topbg {
  background: #fff;
  width: 100%;
}

.top {
  width: 1211px;
  margin: 0 auto;
}
.logo {
  float: left;
  width: 12vw;
  margin-top: 20px;
  margin-bottom: 20px;
}

img {
  border: 0px;
}

.minSearch {
  float: left;
  width: 100%;
  border-bottom: 1px solid #dedede;
  border-top: 1px solid #dedede;
}
.topRight{
  width: calc(100% - 14vw);
  float:left;
  margin-top: 2.3vw;
  margin-left: 2vw;
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
.input-with-select{
  width: 350px!important;
  margin-right: 10px;
}
.min-input-with-select .el-input-group__prepend {
  background-color: #fff;
}
.min-input-with-select{
  width: 90%!important;
  margin: 0px 5%;
}
//导航样式覆盖
.el-menu-item {
  padding: 0 0px !important;
  text-align: center!important;
}
.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: none!important;
  color: #fff!important;
  background: #FF2C2C!important;
}
.el-menu.el-menu--horizontal {
  border-bottom: none!important;
}

.el-menu-item.is-active {
  color: #fff!important;
  background: #FF2C2C!important;
}
</style>
