<!--
   /**
      * banner.vue
      * @module {src/modules/public}
      * @desc 前端banner图
      * @author shiqiu
      * @date 2021-01-20
      */
-->
<template>
  <div>
    <div class="slide banner">
      <el-carousel :interval="5000" height="28vw" arrow="never">
        <el-carousel-item v-for="(banner,bannerList) in bannerImg" :key="bannerList">
          <img :src="banner.bannerBg" style="width: 100%;height: 28vw">
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>


</template>

<script>
export default {
  name: "banner",
  components:{},
  data() {
    return {
      bannerImg: [
        {
          bannerBg: '/static/images/banner01.jpg',
        },
        {
          bannerBg: '/static/images/banner02.jpg',
        },
        {
          bannerBg: '/static/images/banner03.jpg',
        }
      ]
    }
  },
  methods: {

  },
  created() {

  }
}
</script>


<style lang="scss" scoped>
/*走马灯*/
.slide {
  position: relative;
  width: 100%;
}

.banner {
  height: 28vw;
  float: left;
}
.el-carousel--horizontal {
  width: 100%;
}

.el-carousel {
  position: absolute !important;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 250px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #ccdffd;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #ccdffd;
}
</style>
