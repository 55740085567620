<!--
   /**
      * MainLayoutPC.vue
      * @module {src/modules/public}
      * @desc 主页面
      * @author Rich
      * @date 2019-08-08
      * @param {Object} [title]    - 参数说明
      * @param {String} [columns] - 参数说明
      * @example 调用示例
      *  <hbTable :title="title" :columns="columns" :tableData="tableData"></hbTable>
      */
-->
<template>
  <div>
    <Header v-if="!$route.meta.header" />
    <top-nav v-if="!$route.meta.topNav" />
    <Banner v-if="!$route.meta.banner" />
    <transition name="fade-transform" mode="out-in">
      <keep-alive :exclude="/^.*-noCache$/">
        <router-view @changeMsg="changeMsg" :key="$route.fullPath"></router-view>
      </keep-alive>
    </transition>
    <Footer v-if="!$route.meta.footer" />
  </div>
</template>

<script>
import Header from './components/header'
import topNav from './components/topNav'
import Footer from './components/footer'
import Banner from './components/banner'

export default {
  name: "MainLayout",
  components: { Header, Footer, Banner,topNav },
  data() {
    return {

    };
  },

  created() {

  },
  methods: {
    changeMsg(num) {
      this.$refs.header.changeMsg(num);
    }
  },
  watch: {
  }
};
</script>

<style lang="scss" scoped>

</style>
